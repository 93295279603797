

export default {
  props: {
    categorys: Array
  },
  data () {
    return {
      options: {
        rewind: true,
        perPage: 4,
        breakpoints: {
          576: {
            perPage: 1
          },
          768: {
            perPage: 2
          },
          1024: {
            perPage: 3
          }
        },
        gap: '1rem'
      },
      BASE_URL: process.env.BASE_URL,
    }
  },
  computed: {
    product () {
      return this.$t('product')
    },
    availableLocales () {
      return this.$i18n.locales.filter(i => i.code !== this.$i18n.locale)
    }
  }
}
